<template>
  <div>
    <div
      class="card mb-3"
      style="width: 100%"
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="card-header bg-light">
        <h3 class="title fw-normal mb-0 p-2">API Integrations</h3>
        <el-row :gutter="12" class="integration-button-container">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="d-flex">
            <el-input
              placeholder="Search"
              size="small"
              v-model="search_string"
              clearable
              prefix-icon="el-icon-search"
              round
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="searchIntegrations"
                size="mini"
              ></el-button>
            </el-input>
            <el-button
              class="ml-1"
              type="info"
              size="mini"
              round
              @click="showDialog"
              >Add new API Integration</el-button
            >
          </el-col>
        </el-row>
      </div>
      <el-row :gutter="12">
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          class="table-container"
        >
          <template>
            <data-tables-server
              :total="total"
              style="width: 100%"
              :current-page.sync="currentPage"
              :page-size="pageSize"
              :pagination-props="paginationProps"
              :data="data"
              @query-change="loadData"
              @sort-change="sortChange"
            >
              <el-table-column
                label="Name"
                id="name"
                prop="name"
                width="350"
                fixed="left"
                sortable="custom"
              >
              </el-table-column>
              <el-table-column
                label="Actions"
                prop="action"
                class="action"
                width="230"
              >
                <template slot-scope="scope">
                  <ul class="action-buttons hover-buttons">
                    <li>
                      <el-dropdown>
                        <el-button size="mini" class="el-dropdown-link px-2">
                          Actions
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                          <a @click="onEdit(scope.row)">
                            <el-dropdown-item
                              ><i class="el-icon-edit-outline"></i>
                              Edit</el-dropdown-item
                            >
                          </a>
                          <a @click="onDelete(scope.row)">
                            <el-dropdown-item
                              ><i class="el-icon-delete"></i>
                              Delete</el-dropdown-item
                            >
                          </a>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </li>
                  </ul>
                </template>
              </el-table-column>
              <el-table-column
                label="Status"
                id="status"
                prop="status"
                width="350"
                fixed="left"
                sortable="custom"
              >
                <template slot-scope="scope">
                  <span
                    class="status-container"
                    v-if="scope.row && scope.row.status"
                  >
                    <span
                      :class="['circle', scope.row.status.toLowerCase()]"
                    ></span>

                    <span>{{
                      scope.row.status.charAt(0) +
                      scope.row.status.slice(1).toLowerCase()
                    }}</span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="Created at"
                width="300"
                prop="created_at"
                sortable="custom"
              >
                <template slot-scope="scope">
                  <template v-if="scope.row.created_at">
                    <span class="d-inline-block pr-2 text-muted">
                      {{ scope.row.created_at | globalDateTimeFormat }}
                    </span>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                label="Last Modified"
                width="300"
                prop="updated_at"
                sortable="custom"
              >
                <template slot-scope="scope">
                  <template v-if="scope.row.updated_at">
                    <span
                      class="fs-8 d-inline-block pr-2 text-muted activestatus"
                    >
                      {{ scope.row.updated_at | globalDateTimeFormat }}
                    </span>
                  </template>
                </template>
              </el-table-column>
            </data-tables-server>
          </template>
        </el-col>
      </el-row>
    </div>
    <dialog-component
      :visible="showIntegration"
      :title="AppIntegrations"
      @before-close="closeIntegration"
      :containerHeight="'80vh'"
      :containerWidth="'35vw'"
      :isShowFooter="true"
      class="custom-dialog"
    >
      <div
        style="width: 100%"
        v-loading="dailogLoading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-form
          :model="formData"
          :rules="rules"
          ref="form"
          label-position="top"
          class="custom-form"
        >
          <el-form-item
            label="Name"
            prop="name"
            class="custom-form-item"
            label-width="80px"
          >
            <el-input
              v-model="formData.name"
              class="custom-input"
              required
            ></el-input>
          </el-form-item>
          <template v-if="formData.app_type === 'PRE_DEFINED'">
            <el-form-item
              label="Application"
              prop="application"
              class="custom-form-item"
            >
              <el-select
                v-model="formData.application"
                placeholder="Select application"
                class="custom-input"
                @change="setSelectedEvents"
                filterable
              >
                <el-option
                  v-for="option in predefinedIntegrations"
                  :key="option.key + '_' + option._id"
                  :label="option.label"
                  :value="option.key"
                >
                  <template #default>
                    <img :src="option.logo" alt="Logo" class="option-logo" />
                    {{ option.label }}
                  </template>
                </el-option>
              </el-select>
            </el-form-item>
            <template
              v-if="
                this.selectedApplication?.settings?.isOauthAuthenticating &&
                this.selectedApplication.settings.requiredFields &&
                this.selectedApplication.settings.requiredFields.length > 0 &&
                this.enableFields
              "
            >
              <div
                v-for="field in this.selectedApplication.settings
                  .requiredFields"
                :key="field.key"
                class="custom-form-item"
              >
                <el-form-item
                  :label="field.label"
                  :prop="`dependencies.${field.key}`"
                >
                  <div v-if="field.type == 'select'">
                    <el-select
                      v-model="formData.dependencies[field.key]"
                      :placeholder="
                        field.placeholder ? field.placeholder : 'select any'
                      "
                      style="width: 100%"
                      :clearable="true"
                      filterable
                    >
                      <el-option
                        v-for="option in field.values"
                        :key="option.id"
                        :label="option.name"
                        :value="option.id"
                      />
                    </el-select>
                  </div>
                </el-form-item>
              </div>
            </template>
            <template
              v-if="
                !this.selectedApplication?.settings?.isOauthAuthenticating &&
                this.selectedApplication?.settings?.authenticationFields
              "
            >
              <div
                v-for="field in selectedApplication.settings
                  .authenticationFields"
                :key="field.key"
                class="custom-form-item"
              >
                <el-form-item
                  :label="field.label"
                  :prop="`authentication_details.${field.key}`"
                >
                  <div v-if="field.type == 'text'">
                    <el-input
                      v-model="formData.authentication_details[field.key]"
                      :placeholder="
                        field.placeholder ? field.placeholder : 'Enter here'
                      "
                      style="width: 100%"
                    >
                    </el-input>
                  </div>
                </el-form-item>
              </div>
            </template>
            <el-button
              v-if="showSuggestions"
              @click="oAuthSign"
              class="custom-button"
            >
              Sign into {{ selectedApplication.key }}
            </el-button>
          </template>
          <template v-else>
            <el-form-item
              label="Authentication Type"
              prop="authentication_type"
              class="custom-form-item"
            >
              <el-input
                v-model="formData.authentication_type"
                class="custom-input"
              />
            </el-form-item>
            <el-form-item
              label="Authentication Details"
              prop="authentication_details"
              class="custom-form-item"
            >
              <el-input
                type="textarea"
                v-model="formData.authentication_details"
                class="custom-input"
              />
            </el-form-item>
            <el-form-item
              label="Response Type"
              prop="response_type"
              class="custom-form-item"
            >
              <el-select
                v-model="formData.response_type"
                placeholder="Select response type"
                class="custom-input"
              >
                <el-option
                  v-for="option in responseOptions"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="URL" prop="url" class="custom-form-item">
              <div class="d-flex">
                <el-input
                  v-model="formData.url"
                  @change="resetSampleResponse"
                  required
                  class="custom-input"
                />
                <el-button
                  size="mini"
                  @click="testUrl"
                  v-if="isValidURL(formData.url)"
                  v-loading="urlTestLoading"
                  class="custom-button"
                >
                  Test URL
                </el-button>
              </div>
            </el-form-item>
          </template>
        </el-form>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="submitForm" v-if="enbaleFooterButtons"
          >Submit</el-button
        >
        <el-button @click="closeIntegration" v-if="enbaleFooterButtons"
          >Cancel</el-button
        >
      </span>
    </dialog-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { postAPICall } from "@/helpers/httpHelper";
import app from "../../../config/app";
export default {
  name: "APiIntegrations",
  data() {
    return {
      responseOptions: [
        {
          label: "Value",
          value: "VALUE",
        },
        {
          label: "Array",
          value: "ARRAY",
        },
      ],
      loading: false,
      dailogLoading: false,
      urlTestLoading: false,
      testResponseLength: -1,
      testResponse: "",
      data: [],
      AppIntegrations: "Add API Integrations",
      formData: {
        name: "",
        app_type: "PRE_DEFINED",
        dependencies: {},
        authentication_details: {},
        application: "",
        connection_id: "",
      },
      rules: {
        name: [
          { required: true, message: "Please input the name", trigger: "blur" },
          {
            min: 3,
            message: "Name must be at least 3 characters",
            trigger: "blur",
          },
        ],
        application: [
          {
            required: true,
            message: "Please select the application",
            trigger: "blur",
          },
        ],
      },
      showIntegration: false,
      edit: false,
      search_string: "",
      total: 0,
      activeStep: 0,
      currentPage: 1,
      pageSize: 0,
      page: 1,
      order_type: null,
      order_by: null,
      predefinedIntegrations: [],
      selectedApplicationEvents: [],
      selectedApplicationAuthDetails: [],
      applicationOauthDetails: "",
      oauthUrl: "",
      interval: "",
      queryData: null,
      enableFields: false,
    };
  },
  watch: {
    async queryData(newValue) {
      try {
        if (newValue) {
          let response = await this.getTokensUsingAuthorizationCode(newValue);
          if (response?.data) {
            this.formData["connection_id"] = response.data;
            this.enableFields = true;
            this.loadRequiredData();
          } else {
            throw "Error in Authentication";
          }
        }
      } catch (err) {
        console.log("errr", err);
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["getThirdaPartyFormData"]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
    paginationPropsTable() {
      return [10, 20, 50];
    },
    selectedApplication() {
      return this.predefinedIntegrations.find(
        (e) => e.key == this.formData.application
      );
    },
    showSuggestions() {
      return (
        this.selectedApplication &&
        this.selectedApplication?.settings?.isOauthAuthenticating &&
        !this.enableFields &&
        !this.edit
      );
    },
    enbaleFooterButtons() {
      if (!this.selectedApplication?.settings?.isOauthAuthenticating) {
        return true;
      } else {
        if (
          !this.selectedApplication?.settings?.requiredFields ||
          (this.selectedApplication?.settings?.requiredFields &&
            this.enableFields)
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  created() {
    this.interval = setInterval(this.checkLocalStorage, 2000);
  },
  async mounted() {
    await this.fetchAllPredefinedIntegrations();
    await this.fetchIntergrations();
  },
  methods: {
    async checkLocalStorage() {
      let storageInfo = await localStorage.getItem("extractCode");
      this.queryData = await JSON.parse(storageInfo);
      await localStorage.removeItem("extractCode");
    },
    async oAuthSign() {
      if (!this.applicationOauthDetails) {
        return;
      }
      // const { clientId, serviceProviderUrl, scopes } =
      //   this.applicationOauthDetails;
      // let key = (Math.random() + 1).toString(36).substring(7);
      // let encodedScopes = encodeURIComponent(scopes).replace(/%60/g, "");
      // this.oauthUrl = `${serviceProviderUrl}?client_id=${clientId}&redirect_uri=${app.APP_URL}/integrations/callback&response_type=code&scope=${encodedScopes}&state=${key}`;
      // if (this.selectedApplication.key) {
      //   this.oauthUrl += "&access_type=offline";
      // }
      // if (
      //   this.selectedApplication.key !== "faceBookAdsDup" &&
      //   this.selectedApplication.key !== "faceBookPagesDup"
      // ) {
      //   this.oauthUrl +=
      //     "&display=popup&extras=" +
      //     encodeURIComponent('{"setup":{"channel":"IG_API_ONBOARDING"}}');
      // }
      // if (
      //   this.selectedApplication.key !== "instagramBusinessDup" &&
      //   this.selectedApplication.key !== "faceBookAdsDup"
      // ) {
      //   this.oauthUrl += "&prompt=consent";
      // }
      // if (this.selectedApplication.key === "outlook") {
      //   this.oauthUrl += "&prompt=select_account";
      // }
      window.open(
        this.applicationOauthDetails,
        "_blank",
        "width=800,height=600"
      );
    },

    async fetchAllPredefinedIntegrations() {
      const response = await postAPICall(
        "GET",
        "integrations/service/get-all-applications"
      );
      this.predefinedIntegrations = response?.data || [];
    },
    async setSelectedEvents() {
      try {
        if (this.selectedApplication && this.selectedApplication.settings) {
          if (this.selectedApplication.settings.isOauthAuthenticating) {
            const { requiredFields } = this.selectedApplication.settings;
            if (requiredFields && requiredFields.length > 0) {
              this.selectedApplicationAuthDetails = requiredFields;
              this.addDynamicRulesToOauth();
            } else {
              this.selectedApplicationAuthDetails = [];
            }
            await this.fetchApplicationOAuthDetails();
          } else {
            this.addDynamicRulesToNoOauth();
          }
        }
      } catch (error) {
        console.log("Error in setSelectedEvents:", error);
      }
    },

    async fetchApplicationOAuthDetails() {
      const response = await postAPICall(
        "GET",
        "integrations/service/get-application-oauth-deatils",
        {
          application: this.formData?.application,
        }
      );
      this.applicationOauthDetails = response?.data || null;
    },
    isValidURL(url) {
      let re =
        /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    },
    resetSampleResponse() {
      this.formData.sample_response = null;
      this.testResponse = "";
      this.testResponseLength = -1;
    },
    async testUrl() {
      try {
        this.urlTestLoading = true;
        const response = await fetch(this.formData.url);
        const result = await response.json();
        this.testResponse = result;
        this.testUrlResponse();
        this.urlTestLoading = false;
      } catch (error) {
        this.testResponse = `Error: ${error.message}`;
        this.urlTestLoading = false;
      }
    },
    testUrlResponse() {
      if (typeof this.testResponse == "object") {
        if (this.testResponse.length) {
          this.formData.response_type = "ARRAY";
          if (this.testResponse.length > 5) {
            this.testResponseLength = this.testResponse.length - 5;
            this.testResponse = this.testResponse.splice(0, 4);
          }
        } else {
          this.formData.response_type = "VALUE";
        }
      } else {
        this.formData.response_type = "VALUE";
      }
      this.formData.sample_response = this.testResponse;
    },
    async searchIntegrations() {
      this.page = 1;
      this.fetchIntergrations();
    },
    async sortChange(a) {
      this.order_by = a.prop;
      this.order_type = a.order == "ascending" ? "asc" : "desc";
      this.fetchIntergrations();
      if (a.order == null) {
        this.order_by = null;
        this.order_type = null;
        this.fetchIntergrations();
      }
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchIntergrations();
      }
    },
    async fetchIntergrations() {
      this.loading = true;
      let params = {
        limit: this.pageSize > 0 ? this.pageSize : 10,
        page: this.page,
      };
      if (this.order_by) {
        params.order_by = this.order_by;
      }
      if (this.search_string) {
        params.search_string = this.search_string;
      }
      if (this.order_type) {
        params.order_type = this.order_type;
      }
      let integrations = await postAPICall("GET", `integrations`, params);
      let inactiveIntegrations = [];
      const connectionPromises = integrations.data.map(async (integration) => {
        try {
          if (integration.connection_id) {
            const response = await postAPICall(
              "GET",
              `integrations/service/get-tokens-by-connection/${integration.connection_id}`
            );
            integration.status = response?.data?.status || "Unknown";
            if (integration.status.toLowerCase() === "inactive") {
              inactiveIntegrations.push(integration.name);
            }
          } else {
            integration.status = "N/A";
          }
        } catch (error) {
          integration.status = "Error";
        }
      });
      await Promise.all(connectionPromises);
      if (inactiveIntegrations.length > 0) {
        const inactiveList = inactiveIntegrations.join(", ");
        this.$notify({
          type: "warning",
          title: "Reauthorization Required",
          message: `The following integrations are inactive: ${inactiveList}. Please reauthorize.`,
          duration: 6000,
        });
      }

      this.data = integrations.data;
      this.total = integrations.total;
      this.loading = false;
    },
    showDialog() {
      this.AppIntegrations = "Add API Integrations";
      this.showIntegration = true;
    },
    closeIntegration() {
      this.formData = {
        name: "",
        authentication_type: "",
        app_type: "PRE_DEFINED",
        authentication_details: {},
        dependencies: {},
      };
      this.showIntegration = false;
    },
    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            if (this.formData._id) {
              await postAPICall(
                "PUT",
                `integrations/update/${this.formData._id}`,
                this.formData
              );
              this.$message.success("API Integration updated successfully!");
            } else {
              if (!this.selectedApplication.settings.isOauthAuthenticating) {
                let response = await this.validateCredentials();
                if (response?.data) {
                  this.formData.connection_id = response.data;
                } else {
                  throw "Failed to Establish connection";
                }
              }
              let response = await postAPICall(
                "POST",
                "integrations/create",
                this.formData
              );
              if (response.status) {
                this.showIntegration = false;
                this.$message.success(
                  response.message
                    ? response.message
                    : "API Integration updated successfully!"
                );
              } else {
                throw "Failed to save API Integration";
              }
            }
            this.fetchIntergrations();
          } catch (error) {
            this.$message.error(
              error.message ? error.message : "Failed to save API Integration!"
            );
          }
        } else {
          console.error("Form validation failed!");
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    async onDelete(row) {
      this.$confirm("Are you sure want to delete?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(async () => {
          await this.confirmDelete(row);
          this.$message.success("API Integration deleted successfully!");
          this.fetchIntergrations();
          this.$message({
            type: "success",
            message: "Successfully Deleted Integration",
          });
        })
        .catch(() => {
          this.$message.error("Failed to delete API Integration!");
        });
    },

    async confirmDelete(row) {
      try {
        await postAPICall("DELETE", `integrations/delete/${row._id}`);
      } catch (error) {
        this.$message.error("Failed to delete API Integration!");
      }
    },
    async onEdit(row) {
      this.formData = { ...row };
      this.AppIntegrations = "Edit API Integration";
      this.showIntegration = true;
      this.edit = true;
      if (
        this.formData.dependencies &&
        Object.keys(this.formData.dependencies).length
      ) {
        this.enableFields = true;
        this.selectedApplicationAuthDetails =
          this.selectedApplication.settings.requiredFields;
        this.addDynamicRulesToOauth();
        this.loadRequiredData();
      }
      if (
        this.formData.authentication_details &&
        Object.keys(this.formData.authentication_details).length
      ) {
        this.addDynamicRulesToNoOauth();
      }
    },
    async validateCredentials() {
      try {
        let params = {
          appName: this.formData.application,
          authenticationFields: this.formData.authentication_details,
        };
        const response = await postAPICall(
          "POST",
          "integrations/service/get-auth-details",
          params
        );
        return response;
      } catch (error) {
        this.$message({
          message:
            error && error.message
              ? error.message
              : "Failed to create connection",
          type: "error",
        });
      }
    },
    async getTokensUsingAuthorizationCode(query) {
      this.dailogLoading = true;
      try {
        const response = await postAPICall(
          "POST",
          "integrations/service/post-code",
          {
            appName: this.selectedApplication.key,
            code: query.code,
            redirectUrl: `${app.APP_URL}/integrations/callback`,
            name: this.formData.name,
          }
        );
        this.dailogLoading = false;
        return response;
      } catch (e) {
        this.dailogLoading = false;
        console.log("errr", e);
      }
    },
    async loadRequiredData() {
      try {
        if (this.selectedApplicationAuthDetails.length) {
          let filterNoDependencyFields =
            this.selectedApplicationAuthDetails.filter(
              (field) => field.dependencyField?.length === 0
            );
          await Promise.all(
            filterNoDependencyFields.map(async (ndpField) => {
              let payload = {
                appName: this.selectedApplication.key,
                connectionId: this.formData["connection_id"],
                field: ndpField.key,
              };
              let response = await this.getIntegrationRequiredData(payload);
              ndpField.values = response.data;
              return response.data;
            })
          );
        }
      } catch (err) {
        console.log("errrr", err);
      }
    },
    async getIntegrationRequiredData(payload) {
      try {
        this.dailogLoading = true;
        let response = await postAPICall(
          "POST",
          "integrations/service/get-user-data",
          payload
        );
        this.dailogLoading = false;
        return response;
      } catch (err) {
        this.dailogLoading = false;
        console.log("Errr", err);
      }
    },
    addDynamicRulesToOauth() {
      if (this.selectedApplicationAuthDetails.length) {
        this.selectedApplicationAuthDetails.forEach((field) => {
          if (field.isRequired) {
            this.rules[`dependencies.${field.key}`] = [
              {
                required: true,
                message: `Please select ${field.label}`,
                trigger: "change",
              },
            ];
          }
        });
      }
    },
    addDynamicRulesToNoOauth() {
      const { authenticationFields } = this.selectedApplication.settings;
      authenticationFields.forEach((field) => {
        if (field.isRequired) {
          this.rules[`authentication_details.${field.key}`] = [
            {
              required: true,
              message: `${field.label} is required`,
              trigger: "blur",
            },
          ];
        }
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.option-logo {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  vertical-align: middle;
}

.custom-form {
  max-width: 500px;
}
.custom-form-item {
  width: 100%;
}
.custom-input {
  width: 100%;
}
.custom-inputs {
  margin-bottom: 20px;
  width: 100%;
}
.custom-button {
  width: 50%;
  margin-top: 10px;
  margin-bottom: 20px;
}
.d-flex {
  display: flex;
  align-items: center;
}
.response-preview {
  border: 1px solid grey;
  border-radius: 5px;
  background-color: rgba(124, 126, 126, 0.123);
  overflow: auto;
}
.confirm-delete-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confirm-delete-content {
  text-align: center;
  margin: auto;
  padding-top: 10%;
}
.input-in-whatsapp {
  width: 90vh;
}
.integration-button-container {
  position: absolute;
  top: 10px;
  right: 10px;
}
.url-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.el-form-item__label {
  margin-right: 10px;
}

.el-form-item .el-button {
  margin-left: 10px;
}
</style>

<style>
.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0px !important;
  margin: bottom -21px !important;
}
.red-asterisk {
  color: red;
  margin-right: 5px;
}
.circle {
  position: relative;
  padding-left: 20px;
  text-transform: capitalize;
  font-size: 14px;

  &::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    height: 10px;
    width: 10px;
    display: inline-block;
    border-radius: 50%;
  }

  &.active::after {
    background: #08ad36;
  }

  &.inactive::after {
    background: #e3a048;
  }
}
</style>
